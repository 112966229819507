@import url(https://fonts.googleapis.com/css?family=Montserrat:300, 400, 700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  margin:0;
  padding:0;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html {
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	scroll-behavior: smooth;
}
a {
	text-decoration: none;
}
.container {
	min-height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
p {
	color: black;
	font-size: 2rem;
	margin-top: 5px;
	line-height: 2.5rem;
	font-weight: 300;
	letter-spacing: .05rem;
}
.section-title {
	font-size: 4rem;
	font-weight: 300;
	color: black;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: .2rem;
	text-align: center;
}
.section-title span {
	color:  	#dfca4e;
}

.section-title-seperator{
	font-size: 3rem;
	font-weight: 300;
	color: black;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: .2rem;
	text-align: center;
	margin-top: 50px;
}
.section-title-seperator span {
	color:  	#dfca4e;
	cursor: pointer;
}
.cta {
	display: inline-block;
	padding: 10px 30px;
	color: white;
	background-color: transparent;
	border: 2px solid  	#dfca4e;
	font-size: 2rem;
	letter-spacing: .1rem;
	margin-top: 30px;
	transition: .3s ease;
	transition-property: background-color, color;
}
.cta:hover {
	color: white;
	background-color:  	#dfca4e;
}
.brand{
  width: 30%;
}
.brand h1 {
	font-size: 3rem;
	text-transform: uppercase;
	color: white;
}
.brand h1 span {
	color:  	#dfca4e;
}
.brand img{
  margin-left: 20px;
  max-width: 50%;
}

/* Header section */
#header {
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100vw;
	height: auto;
}
#header .header {
	min-height: 8vh;
	background-color: rgba(31, 30, 30, 0.24);
	transition: .3s ease background-color;
}
#header .nav-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	max-width: 1300px;
	padding: 0 10px;
}
#header .nav-list ul {
	list-style: none;
	position: absolute;
	background-color: rgb(31, 30, 30);
	width: 100vw;
	height: 100vh;
	left: 100%;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
	overflow-x: hidden;
	transition: .5s ease left;
}
#header .nav-list ul.active {
	left: 0%;
}
#header .nav-list ul a {
	font-size: 2.5rem;
	font-weight: 500;
	letter-spacing: .2rem;
	text-decoration: none;
	color: white;
	text-transform: uppercase;
	padding: 20px;
	display: block;
}
#header .nav-list ul a::after {
	content: attr(data-after);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	color: rgba(240, 248, 255, 0.021);
	font-size: 13rem;
	letter-spacing: 50px;
	z-index: -1;
	transition: .3s ease letter-spacing;
}
#header .nav-list ul li:hover a::after {
	transform: translate(-50%, -50%) scale(1);
	letter-spacing: normal;
	letter-spacing: initial;
}
#header .nav-list ul li:hover a {
	color:  	#dfca4e;
}
#header .hamburger {
	height: 60px;
	width: 60px;
	display: inline-block;
	border: 3px solid white;
	border-radius: 50%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	cursor: pointer;
	transform: scale(.8);
	margin-right: 20px;
}
#header .hamburger:after {
	position: absolute;
	content: '';
	height: 100%;
	width: 100%;
	border-radius: 50%;
	border: 3px solid white;
	-webkit-animation: hamburger_puls 1s ease infinite;
	        animation: hamburger_puls 1s ease infinite;
}
#header .hamburger .bar {
	height: 2px;
	width: 30px;
	position: relative;
	background-color: white;
	z-index: -1;
}
#header .hamburger .bar::after,
#header .hamburger .bar::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	background-color: white;
	transition: .3s ease;
	transition-property: top, bottom;
}
#header .hamburger .bar::after {
	top: 8px;
}
#header .hamburger .bar::before {
	bottom: 8px;
}
#header .hamburger.active .bar::before {
	bottom: 0;
}
#header .hamburger.active .bar::after {
	top: 0;
}
/* End Header section */

/* home Section */
#home {
	background-image: url("https://live.staticflickr.com/65535/51355675688_74c7c20e80_k.jpg");
	background-size: cover;
	background-position: top center;
	position: relative;
	z-index: 1;
}
#home::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: .7;
	z-index: -1;
}
#home .home {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 50px;
	justify-content: flex-start;
}
#home h1 {
	display: block;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	font-size: 4rem;
	position: relative;
	color: transparent;
	-webkit-animation: text_reveal .5s ease forwards;
	        animation: text_reveal .5s ease forwards;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
}
#home h1:nth-child(1) {
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
}
#home h1:nth-child(2) {
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
}
#home h1:nth-child(3) {
	-webkit-animation: text_reveal_name .5s ease forwards;
	        animation: text_reveal_name .5s ease forwards;
	-webkit-animation-delay: 3s;
	        animation-delay: 3s;
}
#home h1 span {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color:  	#dfca4e;
	-webkit-animation: text_reveal_box 1s ease;
	        animation: text_reveal_box 1s ease;
	-webkit-animation-delay: .5s;
	        animation-delay: .5s;
}
#home h1:nth-child(1) span {
	-webkit-animation-delay: .5s;
	        animation-delay: .5s;
}
#home h1:nth-child(2) span {
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
}
#home h1:nth-child(3) span {
	-webkit-animation-delay: 2.5s;
	        animation-delay: 2.5s;
}

/* End home Section */

/* photos Section */
#photos .photos {
	flex-direction: column;
	text-align: center;
	max-width: 1500px;
	margin: 0 auto;
	padding: 100px 0;
}
#photos .service-top {
	max-width: 500px;
	margin: 0 auto;
}
#photos .service-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 50px;
}
#photos .service-item {
	flex-basis: 80%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding: 30px;
	border-radius: 10px;
	background-image: url("https://cdn.wallpapersafari.com/95/27/0Atdw2.jpeg");
	background-size: cover;
	margin: 10px 5%;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
#photos .service-item::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	opacity: .9;
	z-index: -1;
}
#photos .service-bottom .icon {
	height: 80px;
	width: 80px;
	margin-bottom: 20px;
}
#photos .service-item h2 {
	font-size: 2rem;
	color: white;
	margin-bottom: 10px;
	text-transform: uppercase;
}
#photos .service-item p {
	color: white;
	text-align: left;
}
/* End photos Section */

/* menu section */
#menu .menu {
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
	padding: 100px 0;
}
#menu .menu-header h1 {
	margin-bottom: 50px;
}

#menu .menu h2 {
	margin-top: 50px;
	text-decoration: underline;
	font-size: 3rem;
	color: rgb(58, 57, 57);
	text-align: center;
	cursor: pointer;
}

#menu .all-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
#menu .project-item {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 80%;
	margin: 20px auto;
	overflow: hidden;
	border-radius: 10px;
	cursor: pointer;
}
#menu .project-info {
	padding: 30px;
	flex-basis: 50%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	color: white;
	width: 100%
}
#menu .project-info h1 {
	font-size: 25px;
	font-weight: 300;
	line-height: 2;
	width: 100%;
	cursor: pointer;
	text-align: center;
}
#menu .project-info h2 {
	font-size: 1.8rem;
	font-weight: 500;
	margin-top: 10px;
}
#menu .project-info p {
	color: white;
}
#menu .project-img {
	flex-basis: 50%;
	height: 300px;
	overflow: hidden;
	position: relative;
}
#menu .project-img:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	opacity: .3;
}
#menu .project-img img {
	transition: .3s ease transform;
}
#menu .project-item:hover .project-img img {
	transform: scale(1.1);
}
/* End menu section */

/* About Section */
#about .about {
	display: block;
	flex-direction: column-reverse;
	text-align: center;
	max-width: 1200px;
	margin: 0 auto;
	padding: 100px 20px;
}
#about .col-left {
	width: 250px;
	height: 360px;
}
#about .col-right {
	width: 100%;
}
#about .col-right h2 {
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: .2rem;
	margin-bottom: 10px;
}
#about .col-right p {
	margin-bottom: 20px;
}
#about .col-right .cta {
	color: black;
	margin-bottom: 50px;
	padding: 10px 20px;
	font-size: 2rem;
}
#about .col-left .about-img {
	height: 100%;
	width: 100%;
	position: relative;
	border: 10px solid white;
}
#about .col-left .about-img::after {
	content: '';
	position: absolute;
	left: -33px;
	top: 19px;
	height: 98%;
	width: 98%;
	border: 7px solid  	#dfca4e;
	z-index: -1;
}
/* End About Section */

/* contact Section */
#contact .contact {
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
}
#contact .contact-items {
	width: 400px;
	text-decoration: none;
}
#contact .contact-item {
	text-decoration: none;
	width: 80%;
	padding: 20px;
	text-align: center;
	border-radius: 10px;
	padding: 30px;
	margin: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0px 0px 18px 0 #0000002c;
	transition: .3s ease box-shadow;
}
#contact .contact-item:hover {
	text-decoration: none;
	box-shadow: 0px 0px 5px 0 #0000002c;
}
#contact .icon {
	text-decoration: none;
	width: 70px;
	margin: 0 auto;
	margin-bottom: 10px;
}
#contact .contact-info h1 {
	color: black;
	text-decoration: none;
	font-size: 2.5rem;
	font-weight: 500;
	margin-bottom: 5px;
}
#contact .contact-info h2 {
	color: black;
	text-decoration: none;
	font-size: 1.3rem;
	line-height: 2rem;
	font-weight: 500;
}
/*End contact Section */

/* Footer */
#footer {
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}
#footer .footer {
	min-height: 200px;
	flex-direction: column;
	padding-top: 50px;
	padding-bottom: 10px;
}
#footer h2 {
	color: white;
	font-weight: 500;
	font-size: 1.8rem;
	letter-spacing: .1rem;
	margin-top: 10px;
	margin-bottom: 10px;
}
#footer .social-icon {
	display: flex;
	margin-bottom: 30px;
}
#footer .social-item {
	height: 50px;
	width: 50px;
	margin: 0 5px;
}
#footer .social-item img {
	-webkit-filter: grayscale(1);
	        filter: grayscale(1);
	transition: .3s ease filter;
}
#footer .social-item:hover img {
	-webkit-filter: grayscale(0);
	        filter: grayscale(0);
}
#footer p {
	color: white;
	font-size: 1.3rem;
}
/* End Footer */

/* Keyframes */
@-webkit-keyframes hamburger_puls {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1.4);
	}
}
@keyframes hamburger_puls {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1.4);
	}
}
@-webkit-keyframes text_reveal_box {
	50% {
		width: 100%;
		left: 0;
	}
	100% {
		width: 0;
		left: 100%;
	}
}
@keyframes text_reveal_box {
	50% {
		width: 100%;
		left: 0;
	}
	100% {
		width: 0;
		left: 100%;
	}
}
@-webkit-keyframes text_reveal {
	100% {
		color: white;
	}
}
@keyframes text_reveal {
	100% {
		color: white;
	}
}
@-webkit-keyframes text_reveal_name {
	100% {
		color:  	#dfca4e;
		font-weight: 500;
	}
}
@keyframes text_reveal_name {
	100% {
		color:  	#dfca4e;
		font-weight: 500;
	}
}
/* End Keyframes */

/* Media Query For Tablet */
@media only screen and (min-width: 768px) {
	.cta {
		font-size: 2.5rem;
		padding: 20px 60px;
	}
	h1.section-title {
		font-size: 6rem;
	}

	/* home */
	#home h1 {
		font-size: 7rem;
	}
	/* End home */

	/* photos Section */
	#photos .service-bottom .service-item {
		height: 400px;
		width: 370px;
		flex-basis: 45%;
		margin: 2.5%;
	}
	/* End photos Section */

	/* Project */
	#menu .project-item {
		flex-direction: row;
	}
	#menu .project-item:nth-child(even) {
		flex-direction: row-reverse;
	}
	#menu .project-item {
		height: 400px;
		margin: 0;
		width: 100%;
		border-radius: 0;
	}
	#menu .all-menu .project-info {
		height: 100%;
	}
	#menu .all-menu .project-img {
		height: 100%;
	}
	/* End Project */

	/* About */
	#about .about {
		flex-direction: row;
	}
	#about .col-left {
		width: 600px;
		height: 400px;
		padding-left: 60px;
	}
	#about .about .col-left .about-img::after {
		left: -45px;
		top: 34px;
		height: 98%;
		width: 98%;
		border: 10px solid  	#dfca4e;
	}
	#about .col-right {
		text-align: left;
		padding: 30px;
	}
	#about .col-right h1 {
		text-align: left;
	}
	/* End About */

	/* contact  */
	#contact .contact {
		flex-direction: column;
		padding: 100px 0;
		align-items: center;
		justify-content: center;
		min-width: 20vh;
	}
	#contact .contact-items {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin: 0;
	}
	#contact .contact-item {
		width: 30%;
		margin: 0;
		flex-direction: row;
	}
	#contact .contact-item .icon {
		height: 100px;
		width: 100px;
	}
	#contact .contact-item .icon img {
		object-fit: contain;
	}
	#contact .contact-item .contact-info {
		width: 100%;
		text-align: left;
		padding-left: 20px;
	}
	/* End contact  */
}
/* End Media Query For Tablet */

/* Media Query For Desktop */
@media only screen and (min-width: 1200px) {
	/* header */
	#header .hamburger {
		display: none;
	}
	#header .nav-list ul {
		position: static;
		position: initial;
		display: block;
		height: auto;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		background-color: transparent;
	}
	#header .nav-list ul li {
		display: inline-block;
	}
	#header .nav-list ul li a {
		font-size: 1.8rem;
	}
	#header .nav-list ul a:after {
		display: none;
	}
	/* End header */

	#photos .service-bottom .service-item {
		flex-basis: 22%;
		margin: 1.5%;
	}
}

.modal {
display: flex;
}

.modal-content {
	display: inline;
	width: 100%;
	height: 80%;
}

.modal-header{
  object-position: center; /* Center the image within the element */
  height: 300px;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background:linear-gradient(#ececee,#ececee) center/1px 100% no-repeat;
  grid-gap: 30px;
  margin:30px;
}

.grid-item {
  height: auto;
	font-size: 15px;
	color: rgb(58, 57, 57);
  position: relative;
	padding-left: 10%;
	padding-bottom: 30px;
	border-bottom:  1px solid #ececee;
}

.carousel-style {
    max-width: 100%;
}
